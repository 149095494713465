import React, { useState, useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ComplainRegister.css";

const ComplaintForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    address: "",
    issues: [],
  });
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null); // Ref for the file input field

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({ ...formData, issues: [...formData.issues, value] });
    } else {
      setFormData({
        ...formData,
        issues: formData.issues.filter((issue) => issue !== value),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formDataObj = new FormData();
    formDataObj.append("name", formData.name);
    formDataObj.append("email", formData.email);
    formDataObj.append("number", formData.number);
    formDataObj.append("address", formData.address);
    formDataObj.append("issues", JSON.stringify(formData.issues));
    if (file) {
      formDataObj.append("file", file);
    }

    try {
      const response = await axios.post(
        // "http://localhost:5000/api/complaint"||
        "https://unisafedigital-latest-reactapp-server.vercel.app/api/complaint",

        formDataObj,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success(response.data.message || "Complaint submitted successfully!");

      // Clear all fields and reset the file input
      setFormData({ name: "", email: "", number: "", address: "", issues: [] });
      setFile(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Reset file input field
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error submitting form.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section
        className="about-section py-5"
        style={{ backgroundColor: "#FBFBFB" }}
      >
        <div className="container">
          <div className="row align-items-center mb-5">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="about-image-box wow fadeInLeft">
                <img
                  src="assets/images/registere-form/register-woman.gif"
                  className="img-fluid rounded"
                  alt="Register"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="contact-three_content-box"
                style={{ padding: "50px", backgroundColor: "#FBFBFB" }}
              >
                <h4 style={{ margin: "21px" }}>Enter Your Complaint</h4>
                <form className="form_group input_with_icon" onSubmit={handleSubmit}>
                  <input
                    className="form_control"
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <input
                    className="form_control"
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <input
                    className="form_control"
                    type="text"
                    name="number"
                    placeholder="Phone Number"
                    value={formData.number}
                    onChange={handleChange}
                    required
                  />
                  <textarea
                    className="form_control"
                    name="address"
                    placeholder="Enter Address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                  />
                  <div className="checkbox-group">
                  <h5 style={{margin:'10px' }}>What is The Problem With Your Solar Panel/CCTV Camera?</h5>
                   <div className="row text-center">
                    <div className="col-md-6 text-left">
                    <label>
                      <input
                        type="checkbox"
                        value="System doen't respond"
                        onChange={handleCheckboxChange}
                        checked={formData.issues.includes("System doen't respond")}
                      />
                      System doen't respond
                    </label>

                    <label>
                      <input
                      style={{margin:"5px"}}
                        type="checkbox"
                        value="CCTV Wire Problem"
                        onChange={handleCheckboxChange}
                        checked={formData.issues.includes("CCTV Wire Problem")}
                      />
                      CCTV Wire Problem
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        value="Technical Support"
                        onChange={handleCheckboxChange}
                        checked={formData.issues.includes("Technical Support")}
                      />
                      Technical Support
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        value="Solar Panel Maintenance"
                        onChange={handleCheckboxChange}
                        checked={formData.issues.includes("Solar Panel Maintenance")}
                      />
                      Solar Panel Maintenance
                    </label>
                    
                    </div>
                    <div className="col-md-6 text-left">
                    <label>
                      <input
                        type="checkbox"
                        value="Install New Camera"
                        onChange={handleCheckboxChange}
                        checked={formData.issues.includes("Install New Camera")}
                      />
                      Install New Camera
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        value="Need CCTV Maintenance"
                        onChange={handleCheckboxChange}
                        checked={formData.issues.includes("Need CCTV Maintenance")}
                      />
                      Need CCTV Maintenance
                    </label>

                    <label>
                      <input
                        type="checkbox"
                        value="New Solar Plant"
                        onChange={handleCheckboxChange}
                        checked={formData.issues.includes("New Solar Plant")}
                      />
                      New Solar Plant
                    </label>
                    
                    </div>
                   </div>

                  
                  </div>
                  <input
                    className="form_control"
                    type="file"
                    ref={fileInputRef} // Reference for file input field
                    onChange={handleFileChange}
                  />
                  <button className="form_control" type="submit" disabled={isLoading}>
                    {isLoading ? "Submitting..." : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </section>
    </>
  );
};

export default ComplaintForm;