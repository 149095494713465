import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { IoIosCall } from "react-icons/io";
// import { AiOutlineUser, AiOutlineMail, AiOutlineMessage } from "react-icons/ai";
import { FaSpinner } from "react-icons/fa";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        // "http://localhost:5000/api/contact"||
        "https://unisafedigital-latest-reactapp-server.vercel.app/api/contact",
        formData
      );
      if (response.data.success) {
        toast.success(response.data.message);
        setFormData({ name: "", email: "", phone: "", message: "" });
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Something went wrong. Please try again.");
    }
    setLoading(false);
  };

  return (
    <>
      <ToastContainer />
      <section
        className="page-banner bg_cover p-r z-1"
        style={{ backgroundImage: "url(assets/images/bg/footer-bg-1.webp)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="page-title">
                <h1>Contact Us</h1>
                <ul className="breadcrumbs-link">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="active">Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="contact-information-one p-r z-1 pt-50 ">
            
            <div class="container">
                <div class="row">
                    <div class="col-xl-12 col-lg-12">
                        <div class="contact-two_information-box text-center">
                           
                            <di7v class="row">
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="information-item-two info-one mb-30 wow fadeInDown">
                                        <div class="icon">
                                            <i class="far fa-map-marker-alt"></i>
                                        </div>
                                        <div class="info">
                                            <h5>Office Address</h5>
                                            <address>"G-23, B14 Shree Mansion, Kamla Marg,<br /> C-Scheme, Jaipur"</address>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="information-item-two mb-30 info-three wow fadeInDown">
                                        <div class="icon">
                                            <i class="far fa-phone"></i>
                                        </div>
                                        <div class="info">
                                            <h5>Phone Number</h5>
                                            <p><a href="tel:+917340044005">+91 73-4004-4005</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-12">
                                    <div class="information-item-two mb-30 info-two wow fadeInUp">
                                        <div class="icon">
                                            <i class="far fa-envelope-open-text"></i>
                                        </div>
                                        <div class="info">
                                            <h5>Email Address</h5>
                                            <p><a href="mailto:unisafedigisolar@gmail.com">unisafedigisolar@gmail.com</a></p>
                                        </div>
                                    </div>
                                </div>
                                

                            </di7v>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>

      <section className="pb-50 pt-50">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="map-box">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14230.757000450081!2d75.78874603955077!3d26.913355600000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db5127f54b695%3A0xaaf8f35baa4679f2!2sUnisafe%20Digital!5e0!3m2!1sen!2sin!4v1659266405184!5m2!1sen!2sin"
                  width="100%"
                  height="620"
                  title="mymap"
                  style={{ border: "2px solid" }}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="col-md-6">
              <div className="contact-three_content-box">
                <div style={{ margin: "21px" }}>
                  <h4>Enter Your Query</h4>
                </div>
                <div className="contact-form">
                  <form onSubmit={handleSubmit}>
                    <div className="form_group">
                      <div className="input-wrapper">
                        
                        <input
                          type="text"
                          name="name"
                          className="form_control"
                          placeholder="Full Name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form_group">
                      <div className="input-wrapper">
                       
                        <input
                          type="email"
                          name="email"
                          className="form_control"
                          placeholder="Email Address"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form_group">
                      <div className="input-wrapper">
                       
                        <input
                          type="number"
                          name="phone"
                          className="form_control"
                          placeholder="Contact Number"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form_group">
                      <div className="input-wrapper">
                        
                        <textarea
                          className="form_control"
                          placeholder="Write Message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                    </div>
                    <div className="form_group">
                      <button type="submit" disabled={loading}>
                        {loading ? (
                          <>
                            <FaSpinner className="spinner-icon" />
                            Sending...
                          </>
                        ) : (
                          "Send Message"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactForm;
