import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Box,
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  CircularProgress,
  Card,
  CardContent,
  IconButton,
  useMediaQuery,
  Drawer,
  Link,
} from "@mui/material";
import { Dashboard, Contacts, Report, Settings, Logout, Person, Menu } from "@mui/icons-material";
import axios from "axios";
import { useTheme } from '@mui/material/styles';

const AdminPanel = () => {
  const [contact, setContacts] = useState([]);
  const [complaint, setComplaints] = useState([]);
  const [totalContacts, setTotalContacts] = useState(0);
  const [totalComplaints, setTotalComplaints] = useState(0);
  const [showContent, setShowContent] = useState("dashboard");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const adminUser = {
    username: "admin",
    password: "updesh1",
  };

  const handleLogin = () => {
    if (username === adminUser.username && password === adminUser.password) {
      setIsLoggedIn(true);
    } else {
      alert("Invalid username or password");
    }
  };

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const [contactsResponse, complaintsResponse] = await Promise.all([
        axios.get("https://unisafedigital-latest-reactapp-server.vercel.app/api/contact" ),
        axios.get("https://unisafedigital-latest-reactapp-server.vercel.app/api/complaint"),
      ]);

      setTotalContacts(contactsResponse.data.length);
      setTotalComplaints(complaintsResponse.data.length);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setLoading(false);
    }
  };

  const handleContactClick = () => {
    setShowContent("contacts");
    setLoading(true);
    axios
      //.get("http://localhost:5000/api/contact")
      .get("https://unisafedigital-latest-reactapp-server.vercel.app/api/contact")
      .then((response) => {
        setContacts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching contacts:", error);
        setLoading(false);
      });
  };

  const handleManageContentClick = () => {
    setShowContent("complaints");
    setLoading(true);
    axios
      // .get("http://localhost:5000/api/complaint")
      .get("https://unisafedigital-latest-reactapp-server.vercel.app/api/complaint")
      .then((response) => {
        setComplaints(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching complaints:", error);
        setLoading(false);
      });
  };

  const handleDashboardClick = () => {
    setShowContent("dashboard");
    fetchDashboardData();
  };

  useEffect(() => {
    if (showContent === "dashboard") {
      fetchDashboardData();
    }
  }, [showContent]);

  if (!isLoggedIn) {
    return (
      <Container maxWidth="xs" sx={{ mt: 5, mb: 5 }}>
        <Typography variant="h5" gutterBottom>
          Admin Login
        </Typography>
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button variant="contained" color="primary" fullWidth onClick={handleLogin}>
          Login
        </Button>
      </Container>
    );
  }

  const renderSidebar = () => (
    <Box sx={{ width: isMobile ? '200px' : '240px', padding: '20px', backgroundColor: '#f5f5f5' }}>
      <Typography variant="h6" gutterBottom>
        Admin Panel
      </Typography>
      <Box>
        <Button fullWidth variant="outlined" startIcon={<Dashboard />} onClick={handleDashboardClick}>
          Dashboard
        </Button>
        <Button fullWidth variant="outlined" startIcon={<Contacts />} onClick={handleContactClick}>
          Contacts
        </Button>
        <Button fullWidth variant="outlined" startIcon={<Report />} onClick={handleManageContentClick}>
          Complaints
        </Button>
        <Button fullWidth variant="outlined" startIcon={<Settings />}>
          Settings
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box sx={{
      display: 'flex',
      minHeight: '100vh',
      backgroundImage: `url('aasets/images/logo/logo.webp')`, // Use the path to your background image
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      position: 'relative',
    }}>
      {/* Sidebar */}
      {isMobile ? (
        <Drawer anchor="left" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
          {renderSidebar()}
        </Drawer>
      ) : (
        <Box sx={{ width: '240px', backgroundColor: '#f5f5f5', height: '100vh' }}>
          {renderSidebar()}
        </Box>
      )}

      {/* Main Content */}
      <Box sx={{ flexGrow: 1, padding: '20px' }}>
        {/* Header */}
        <AppBar position="static" sx={{ marginBottom: '20px' }}>
          <Toolbar>
            {isMobile && (
              <IconButton edge="start" color="inherit" onClick={() => setDrawerOpen(true)}>
                <Menu />
              </IconButton>
            )}
            <Typography variant="h6">Dashboard</Typography>
            <Box sx={{ marginLeft: 'auto' }}>
              <IconButton color="inherit">
                <Person />
              </IconButton>
              <Button color="inherit" onClick={() => setIsLoggedIn(false)} startIcon={<Logout />}>
                Logout
              </Button>
            </Box>
          </Toolbar>
        </AppBar>

        <Container>
          <Grid container spacing={2}>
            {loading && (
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <CircularProgress />
                <Typography variant="body1">Loading data, please wait...</Typography>
              </Grid>
            )}

            {/* Dashboard Section */}
            {!loading && showContent === "dashboard" && (
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ marginBottom: '20px' }}>
                  Dashboard Overview
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        backgroundColor: '#f0f4c3',
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': { transform: 'scale(1.05)' },
                      }}
                    >
                      <CardContent>
                        <Typography variant="h5">Total Contacts</Typography>
                        <Typography variant="h3">{totalContacts}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Card
                      sx={{
                        backgroundColor: '#ffcc80',
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': { transform: 'scale(1.05)' },
                      }}
                    >
                      <CardContent>
                        <Typography variant="h5">Total Complaints</Typography>
                        <Typography variant="h3">{totalComplaints}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* Contacts Section */}
            {!loading && showContent === "contacts" && (
              <Grid item xs={12}>
                <Paper>
                  <Typography variant="h6" sx={{ margin: "20px" }}>
                    Contacts List
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Phone</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contact.map((contact) => (
                        <TableRow key={contact.id}>
                          <TableCell>{contact.name}</TableCell>
                          <TableCell>{contact.email}</TableCell>
                          <TableCell>{contact.message}</TableCell>
                          <TableCell>{contact.phone}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            )}

            {/* Complaints Section */}
            {!loading && showContent === "complaints" && (
              <Grid item xs={12}>
                <Paper>
                  <Typography variant="h6" sx={{ margin: "20px" }}>
                    Complaints List
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Address</TableCell>
                        <TableCell>View</TableCell>
                        
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {complaint.map((complaint) => (
                        <TableRow key={complaint.id}>
                          <TableCell>{complaint.name}</TableCell>
                          <TableCell>{complaint.email}</TableCell>
                          <TableCell>{complaint.number}</TableCell>
                          <TableCell>{complaint.address}</TableCell>
                          <TableCell>

                          {complaint.fileUrl ? (
                    <Link
                      // href={`http://localhost:5000${complaint.fileUrl}`}
                      href={`https://unisafedigital-latest-reactapp-server.vercel.app${complaint.fileUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View File
                    </Link>
                  ) : (
                    "No File"
                  )} 
                          </TableCell>
                          
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default AdminPanel;

